import React from 'react';
import logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className="flex items-center justify-end p-4 z-10 float-right">
      <div className="ml-auto">
        <img src={logo} alt="logo" className="w-auto" />
      </div>
    </header>
  );
};

export default Header;
