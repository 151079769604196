import React from 'react';
import { useNavigate } from 'react-router-dom';
import nextButton from '../assets/nextButton.png';
import nextButtonR from '../assets/nextButtonR.png';

function NavigationButtons({ previousPage, nextPage, previousLabel, nextLabel, allowedTurnBack, allowedToAdvance }) {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    if (previousPage && allowedTurnBack) {
      navigate(previousPage);
    }
  };

  const goToNextPage = () => {
    if (nextPage && allowedToAdvance) {
      navigate(nextPage);
    }
  };

  return (
    <div className="fixed bottom-0 w-full  p-4">
      <div className="flex justify-between">
        {previousPage && (
          <button
            className={`p-2 mx-2 focus:outline-none ${
              !allowedTurnBack ? 'cursor-not-allowed opacity-50' : ''
            }`}
            onClick={goToPreviousPage}
            disabled={!allowedTurnBack}
            
          >
            <img
              src={nextButtonR}
              alt={previousLabel || 'Anterior'}
              className="w-16 h-16 object-cover"
            />
          </button> 
        )}
        {nextPage && (
          <button
            className={`p-2 mx-2 focus:outline-none ${
              !allowedToAdvance ? 'cursor-not-allowed opacity-50' : ''
            }`}
            onClick={goToNextPage}
            disabled={!allowedToAdvance}
          >
            <img
              src={nextButton}
              alt={nextLabel || 'Próximo'}
              className="w-16 h-16 object-cover"
            />
          </button>
        )}
      </div>
    </div>
  );
}


export default NavigationButtons;
