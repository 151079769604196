import React, { useState } from 'react';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Fab } from '@mui/material';

function FullScreenButton() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    const bodyElement = document.body;

    try {
      if (!document.fullscreenElement) {
        bodyElement.requestFullscreen().then(() => setIsFullscreen(true));
      } else {
        document.exitFullscreen().then(() => setIsFullscreen(false));
      }
    } catch (error) {
      console.error("An error occurred while toggling fullscreen:", error);
    }
  };

  return (
    <Fab
      size="small"
      color="dark"
      aria-label="fullscreen"
      style={{ position: 'fixed', top: 16, left: 16, opacity: 0.1 }}
      onClick={toggleFullscreen}
    >
      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
    </Fab>
  );
}

export default FullScreenButton;
