import React from 'react';
import { BrowserRouter as Router, Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom';
import StartScreen from './screen/StartScreen';
import './App.css'
import QuizScreen from './screen/QuizScreen';
import StartScreenTwo from './screen/StartScreenTwo';
import StartScreenThree from './screen/StartScreenThree';
import StartScreenFour from './screen/StartScreenFour';
import StartScreenFive from './screen/StartScreenFive';
import NotFound from './screen/notFound';
import ExtraScreen from './screen/ExtraScreen';
import GraphScreen from './screen/GraphScreen';
import LastScreen from './screen/LastScreen';
import Dashboard from './screen/DashBoard';



const router = createBrowserRouter([
  {
    path: '/',
    element: <StartScreen />
  },
  {
    path: '/amor',
    element: <StartScreenTwo />
  },
  {
    path: '/autoconhecimento',
    element: <StartScreenThree />
  },
  {
    path: '/relacionamentos', //     path: '/quiz/:id',
    element: <StartScreenFour />
  },
  {
    path: '/treinamento', //     path: '/quiz/:id',
    element: <StartScreenFive />
  },

  {
    path: '/quiz/:id', //     path: '/quiz/:id',
    element: <QuizScreen />
  },
  {
    path: '/extra', //     path: '/quiz/:id',
    element: <ExtraScreen />
  },
  {
    path: '/dash', //     path: '/quiz/:id',
    element: <Dashboard />
  },

  {
    path: '/sinais', //     path: '/quiz/:id',
    element: <GraphScreen />
  },

  {
    path: '/conclusao', //     path: '/quiz/:id',
    element: <LastScreen />
  },
  {
    path: '/*', //     path: '/quiz/:id',
    element: <NotFound />
  },
])
function App() {
  return (
    <div className='bg-primary h-screen'>
      {<RouterProvider router={router}></RouterProvider>}
    </div>
  );
}

export default App;