import React, { useState, useEffect } from 'react';
import { questions } from '../data/questionList';
import nextButton from '../assets/nextButton.png';
import { useNavigate, useParams } from 'react-router-dom';
import { serverTimestamp, getDoc, updateDoc, doc, getDocs, collection } from 'firebase/firestore';
import { FIRESTORE_DB } from '../firebase';
import { useSpring } from '@react-spring/web';
import '../App.css';
import Conclusion6 from './Conclusion6';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
const QuizScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [step, setStep] = useState('dialog');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skipWait, setSkipWait] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [skipButtonEnabled, setSkipButtonEnabled] = useState(true);
  const [showSkipButton, setShowSkipButton] = useState(true);
  const [dialogMode, setDialogMode] = useState(false);
  const [inactivityDialogOpen, setInactivityDialogOpen] = useState(false);
 

  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });
 
  useEffect(() => {
    let inactivityTimer;

    const handleInactivity = () => {
      setInactivityDialogOpen(true);

      setTimeout(() => {
          navigate('/')
      }, 10000); // 10 seconds timeout
    };

    inactivityTimer = setTimeout(handleInactivity, 60000); // 1 minute timeout

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleInactivity, 60000); // 1 minute timeout
    };

    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
    };
  }, []);

  // useEffect(() => {
  //   const fetchQuizCount = async () => {
  //     try {
  //       const quizzesCollection = collection(FIRESTORE_DB, 'quizzes');
  //       const quizzesSnapshot = await getDocs(quizzesCollection);
  //       const totalQuizzes = quizzesSnapshot.size;

  //       console.log('Total Quizzes:', totalQuizzes);
  //     } catch (error) {
  //       console.error('Error fetching quiz count: ', error);
  //     }
  //   };

  //   fetchQuizCount();
  // }, []);

  useEffect(() => {
    const updateQuizCount = async () => {
      try {
        const quizDocRef = doc(FIRESTORE_DB, 'quizzes', id);
        const quizDoc = await getDoc(quizDocRef);

        if (quizDoc.exists()) {
          const quizData = quizDoc.data();
          const reachedFifthQuestion = quizData.reachedFifthQuestion || false;

          if (!reachedFifthQuestion && currentQuestionIndex >= 5) {
            const updatedQuizData = {
              reachedFifthQuestion: true,
              endTime: serverTimestamp(),
            };

            await updateDoc(quizDocRef, updatedQuizData);
            console.log('User reached the fifth question!');
          }
        }
      } catch (error) {
        console.error('Error updating quiz count: ', error);
      }
    };

    updateQuizCount();
  }, [currentQuestionIndex, id]);

  const handleAnswerClick = (selectedOption, isCorrect) => {
    let updatedScore = score;

    if (isCorrect) {
      updatedScore += 1;
      setScore(updatedScore);
    }

    const quizDocRef = doc(FIRESTORE_DB, 'quizzes', id);
    const updateData = {
      [`answers.${currentQuestionIndex}`]: {
        pergunta: `pergunta${currentQuestionIndex + 1}`,
        isCorreta: !!isCorrect,
        respostaSelecionada: selectedOption,
      },
      score: updatedScore,
    };

    updateDoc(quizDocRef, updateData)
      .then(() => {
        console.info('Resposta armazenada com sucesso');
      })
      .catch((error) => {
        console.error('Erro ao armazenar resposta:', error);
      });

    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        questionId: questions[currentQuestionIndex].id,
        isCorrect,
        respostaSelecionada: selectedOption,
      },
    ]);
    setStep('conclusion');
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setStep('dialog');
      setSkipWait(false);
    } else {
      setLoading(true);
      setQuizCompleted(true);
    }
  };

  const handleSkipWait = () => {
    setSkipButtonEnabled(false);
    setSkipWait(true);
    setShowSkipButton(false);

    if (questions[currentQuestionIndex] && questions[currentQuestionIndex].questionText) {
      setStep('question');
    } else {
      setStep('conclusion');
    }

    setTimeout(() => {
      setSkipButtonEnabled(true);
      setShowSkipButton(true);
    }, 1000);
  };

  const handleContinueQuiz = () => {
    setInactivityDialogOpen(false);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setStep('question');
    }, 60000); // 1 minute timeout

    // Defina um timeout adicional para reiniciar o quiz após 30 segundos se o usuário não clicar em "Continuar" ou "Cancelar"
    setTimeout(() => {
      navigate('/');
    }, 30000);
  };

  const handleRestartQuiz = () => {
    setInactivityDialogOpen(false);
    navigate('/');
  };
  useEffect(() => {
    if (step === 'dialog') {
      if (skipWait) {
        setStep('question');
      } else {
        timerRef.current = setTimeout(() => {
          setInactivityDialogOpen(true);
        }, 60000);
      }

      return () => clearTimeout(timerRef.current);
    }
  }, [step, skipWait]);


  useEffect(() => {
    let timer;
    if (dialogMode) {
      setSkipButtonEnabled(false);
      timer = setTimeout(() => {
        setSkipButtonEnabled(true);
      }, 10000);
    } else {
      setSkipButtonEnabled(true);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [dialogMode]);


  useEffect(() => {
    if (step === 'dialog') {
      if (skipWait) {
        setStep('question');
      } else {
        timerRef.current = setTimeout(() => {
          setStep('question');
        }, 60000);
      }

      return () => clearTimeout(timerRef.current);
    }
  }, [step, skipWait]);

  const timerRef = React.useRef();

  return (
    <div>
      <div className=''>
        {userAnswers.length === questions.length - 1 ? (
          <Conclusion6 />
        ) : (
          <>
            {step === 'dialog' && (
              <div className='pt-2 px-12'>
                <p className='uppercase text-4xl mb-8 pt-2'>
                  Cenário 0{currentQuestionIndex + 1}
                </p>
                <div className='typing-animation-container'>
                  {questions[currentQuestionIndex].dialog.map((line, index) => (
                    <div style={fade} key={index} className='typing-animation-line mx-auto'>
                      <p className='text-2xl'>{line.person} </p>
                      <p className='text-2xl'>{line.line}</p>
                    </div>
                  ))}
                </div>
                {!skipWait && (
                  <div className='absolute flex justify-end mt-8 mb-1 inset-x-0 bottom-0 mx-1'>
                    <button
                      onClick={handleSkipWait}
                      className={`p-2 mb-1 focus:outline-none ${!skipButtonEnabled ? 'cursor-not-allowed opacity-50' : 'opacity-100'
                        }`}
                      disabled={!skipButtonEnabled}>
                      <img src={nextButton} alt='Próximo' className='w-[4rem] object-cover' />
                    </button>
                  </div>
                )}
              </div>
            )}

            {step === 'question' && (
              <div className='mx-12 flex flex-col items-center justify-center h-screen'>
                <p className='uppercase text-xl mb-8'>Pergunta 0{currentQuestionIndex + 1}:</p>
                <p>{questions[currentQuestionIndex].questionText}</p>
                <ul>
                  {questions[currentQuestionIndex].answerOptions.map((answer, index) => (
                    <button
                      key={index}
                      className='shadow-lg flex text-white w-full bg-white/20 p-5 m-5'
                      onClick={() => handleAnswerClick(answer.answerText, answer.isCorrect)}>
                      <span>{answer.answerText}</span>
                    </button>
                  ))}
                </ul>
              </div>
            )}

            {step === 'conclusion' && (
              <div className='mx-12 flex flex-col items-center justify-center h-screen'>
                <p className='uppercase text-xl'>Pergunta 0{currentQuestionIndex + 1}</p>
                <p className='text-xl mb-8 mt-2'>Conclusão desta pergunta:</p>
                <p className='text-center text-3xl'>
                  {questions[currentQuestionIndex].conclusao}
                </p>
                {currentQuestionIndex < questions.length - 1 ? (
                  <div className='absolute flex justify-end mt-8 mb-1 inset-x-0 bottom-0 mx-1'>
                    <button className='p-2 mb-1' onClick={handleNextClick}>
                      <img
                        src={nextButton}
                        alt='Próximo'
                        className='w-[4rem] object-cover'
                      />
                    </button>
                  </div>
                ) : (
                      <div className='absolute flex justify-end mt-8 mb-1 inset-x-0 bottom-0 mx-1'>
                    <button className='p-2 mb-1' onClick={() => navigate('/sinais')}>
                      <img
                        src={nextButton}
                        alt='Próximo'
                        className='w-[4rem] object-cover'
                      />
                    </button>
                  </div>
                )}
              </div>
            )}
<Dialog open={inactivityDialogOpen} className='bg-primary'>
  <DialogTitle>Alguém aí?</DialogTitle>
  <DialogContent>
    <p>Se você ainda está por aqui, clique em "Continuar" para voltar ao quiz. Caso contrário, clique em "Sair" para recomeçar.</p>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleContinueQuiz} autoFocus>
      Continuar
    </Button>
    <Button onClick={handleRestartQuiz}>Sair</Button>
  </DialogActions>
</Dialog>

          </>
        )}
      </div>
    </div>
  );
};

export default QuizScreen;