export const data = [ 
    {
        title: '1 - IGNORAR',
        content: (<span>Te ignora. <br/> Faz você se sentir invisível, triste ou com medo.</span>)
    },
    {
        title: '2 - CHANTAGEAR',
        content: (<span>Te ameaça.</span>)
    },
    {
        title: '3 - HUMILHAR',
        content: (<span>Te insulta. Faz você se sentir mal sobre quem você é.<br /> <br /> Envergonha você na frente dos outros para te dar amendrontar.</span>)
    },
    {
        title: '4 - MANIPULAR',
        content: (<span>Se faz de vítima. <br /> Ameaça te bater.</span>)
    },
    {
        title: '5 - CIÚMES',
        content: (<span>Analisa todas as suas conversas. <br /> Você tem medo de que ele(a) possa ouvir qualquer <br />coisa que você faz.</span>)
    },
    {
        title: '6 - CONTROLAR',
        content: (<span>Pede para você trocar o que veste. </span>)
    },
    {
        title: '7 - INTRUSÃO',
        content: (<span>Te ignora. <br /> Faz você se sentir invisível, triste ou com  <br />medo.</span>)
    },
    {
        title: '8 - ISOLAMENTO',
        content: (<span>Quer que você corte todos os laços com  <br /> familia/amigos.</span>)
    },
    {
        title: '9 - INTIMIDAÇÃO',
        content: (<span>Faz você sentir medo. <br /> Você sente medo de se posicionar.</span>)
    },
]