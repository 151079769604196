import React, { useState,useEffect} from 'react';
import NavigateButtons from '../components/NavigateButtons';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import {FIRESTORE_DB} from '../firebase'
import nextButton from '../assets/nextButton.png';
import nextButtonR from '../assets/nextButtonR.png';
function StartScreenFive() {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const goToPreviousPage = () => {
      navigate('/relacionamentos');
  };
  

  const start = async () => {
    try {
      await setDoc(doc(FIRESTORE_DB, 'counters', 'startButtonCount'), {
        count: count + 1,
      });
      
      setCount(count + 1);
  
      const quizDocRef = await addDoc(collection(FIRESTORE_DB, 'quizzes'), {
        startTime: serverTimestamp(),
        score: 0,
        answers: [],
      });
  
      await navigate(`/quiz/${quizDocRef.id}`);
    } catch (error) {
      console.error('Error updating count or creating quiz document:', error);
    }
  };
  



  return (
    <section className='items-center place-items-center place-content-center '>
        <Header />
      <div className='p-10 grid gap-4'>
        <h1 className='text-3xl mb-10'>O TREINAMENTO</h1>
        <p className='text-2xl'>
          Conheça João e Maria. <br/>
           Eles estão namorando há dois anos, e nós estamos te pedindo que nos ajude a identificar os sinais da violência por parceiro íntimo nas seguintes situações.
        </p>
        <p  className='text-2xl'>
          Se você tiver dificuldade para encontrar a resposta certa, não se preocupe - vamos te ajudar ao longo do caminho.
        </p>
      </div>


      <div className="fixed bottom-0 w-full  p-4">
      <div className="flex justify-between">
      
          <button
            className={'p-2 mx-2 focus:outline-none' }
            onClick={goToPreviousPage}
            
          >
            <img
              src={nextButtonR}
              alt={ 'Anterior'}
              className="w-16 h-16 object-cover"
            />
          </button> 
     
       
          <button
            className={'p-2 mx-2 focus:outline-none'}
            onClick={start}
          >
            <img
              src={nextButton}
              alt={'Próximo'}
              className="w-16 h-16 object-cover"
            />
          </button>
      </div>
    </div>
    </section>
  );
}

export default StartScreenFive;
