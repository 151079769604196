import React, { useEffect } from 'react';
import nextButton from '../assets/nextButton.png';
import { useNavigate } from 'react-router-dom';
import NavigateButtons from '../components/NavigateButtons';
import Header from '../components/Header';

function LastScreen() {
  const navigate = useNavigate();

  const goToNextPage = () => {
    navigate('/extra');
  };

  const goToQuiz = () => {
    navigate('/');
  };

  useEffect(() => {
    const timeout = 60000;

    const timer = setTimeout(goToQuiz, timeout);

    return () => clearTimeout(timer);
  }, []);
  return (
    <section>
      <Header />
      <div className='text-center mx-12 flex flex-col items-center justify-center h-screen text-xl'>
        <p>
          Lembre-se que a ocorrência de mais de um sinal ou a alteração na
          <br />
          intensidade pode indicar que o abuso está se agravando. Fique atento a
          <br />
          essas alterações porque o agravamento leva ao aumento do risco de
          perigo
          <br />
          para as vítimas de abuso.
        </p>
        <p className='mt-5'>Fique atenta e denuncie</p>
        <button
          className='mt-10 outline rounded-2xl p-2 text-2xl '
          onClick={goToQuiz}
        >
          Voltar ao início do quiz
        </button>
      </div>
      <div className='absolute flex justify-end mt-8 mb-1 inset-x-0 bottom-0 mx-1'>
        <NavigateButtons
          previousPage='/sinais'
          nextPage='/conclusao'
          previousLabel='Anterior'
          nextLabel='Próximo'
          allowedToAdvance={false}
          allowedTurnBack={true}
        />
      </div>
    </section>
  );
}

export default LastScreen;
