import React from 'react'
import nextButton from '../assets/nextButton.png';
import nextButtonR from '../assets/nextButtonR.png';
import graph from '../assets/art.png'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header';
import "../App.css"

function GraphScreen() {
    const navigate = useNavigate();
    const goToNextPage = () => {
        navigate('/conclusao')
    }
    return (
        <div className={"items-center place-items-center place-content-center graph h-screen"} >
          
            <div className="fixed bottom-0 w-full  p-4">
      <div className="flex justify-end">
          <button
            className={'p-2 mx-2 focus:outline-none'}
            onClick={goToNextPage}
          >
            <img
              src={nextButton}
              alt={ 'Próximo'}
              className="w-16 h-16 object-cover"
            />
          </button>
     
      </div>
    </div>       
     </div>
    )
}

export default GraphScreen