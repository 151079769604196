import React from 'react';
import { useNavigate } from 'react-router-dom';
import nextButton from '../assets/nextButton.png';
import nextButtonR from '../assets/nextButtonR.png';
import vector from '../assets/autoconhecimentodraw.png';
import NavigateButtons from '../components/NavigateButtons';

import Header from '../components/Header'

function StartScreenThree() {
  const navigate = useNavigate();

  const goToNextPage = () => {
    navigate('/relacionamentos');
  }

  const goToBack = () => {
    navigate('/');
  } 

  return (
    <>
      <div className='flex justify-end items-center '>
        <Header />
      </div>
      <section className="relative flex flex-col justify-center h-screen mt-[-6rem]">
        <div className="mx-12">
          <div className="grid grid-cols-2 gap-5 items-center">
            <div>
              <img alt='Coração com um símbolo de atenção triangular e amarelo' src={vector} className='object-cover mx-auto' />
            </div>
            <div className='gap-2'  >
              <h1 className='text-5xl'> VOCÊ SE CONHECE <br /> MELHOR DO QUE <br />NINGUÉM </h1>
              <p className='my-4'>
                Durante este treinamento, podem ocorrer
                situações difíceis, traumáticas ou assustadoras a
                respeito do comportamento abusivo que
                poderão te trazer emoções e sentimentos
                intensos.
              </p>
              <p>
                Se você estiver sofrendo abuso sexual ou físico,
                entre em contato com a polícia ou uma
                organização especializada.
                <br />
                <b className='mt-2'>  ENTRE EM CONTATO COM A ASSOCIAÇÃO MAIS PRÓXIMA. </b>
              </p>
            </div>
          </div>
        </div>

        <div className="absolute flex justify-between mt-8 mb-1 inset-x-0 bottom-0">
          <NavigateButtons previousPage="/amor" nextPage="/relacionamentos" previousLabel="Anterior" nextLabel="Próximo" allowedTurnBack={true} allowedToAdvance={true} />
        </div>
      </section>
    </>
  )
}

export default StartScreenThree;
