export const questions = [
  {
    //PERGUNTA 01
    id: 1,
    questionText: (
      <span>
        Qual dos sinais está evidente aqui?
      </span>
    ),
    answerOptions: [
      { questionId: 1, answerText: 'Chantagear Maria caso ela se recuse a fazer algo', isCorrect: false },
      { questionId: 2, answerText: 'Humilhação ou menosprezar Maria', isCorrect: false },
      { questionId: 3, answerText: 'Isolamento ou afastar Maria de sua família e amigos', isCorrect: false },
      { questionId: 4, answerText: 'Intrusão ou invadir a privacidade de Maria', isCorrect: true },
    ],
    conclusao: (
      <span>
        <h1>Intrusão.</h1>
        <p>Pedir para Maria compartilhar sua localização o tempo todo é uma invasão de privacidade</p>
      </span>
    ),
    dialog: [
      //CENARIO 01
      { person: 'João:', line: '- Olá, você vai sair neste final de semana?' },
      { person: 'Maria:', line: '- Provavelmente' },
      { person: 'João:', line: '- Sério, de novo?' },
      { person: 'Maria:', line: '- Eu não vejo meus amigos desde que começamos a morar juntos' },
      { person: 'João:', line: '- Então é melhor você compartilhar sua localização comigo a noite toda.' },
      { person: 'Maria:', line: '- Por quê? Vamos aos mesmos lugares de sempre e você sabe onde pode me encontrar' },
      { person: 'João:', line: '- Se você quiser sair mais com seus amigos do que comigo, então você pode ir morar em outro lugar' },

    ],
  },
  {
    //PERGUNTA 02
    id: 2,
    questionText: (
      <span>
        Qual dos sinais está evidente aqui?
      </span>
    ),
    answerOptions: [
      { questionId: 1, answerText: 'Humilhação ou menosprezar Maria', isCorrect: false },
      { questionId: 2, answerText: 'Controlar ou decidir por Maria', isCorrect: false },
      { questionId: 3, answerText: 'Isolamento ou afastar Maria de sua família e amigos', isCorrect: true },
    ],
    conclusao: (
      <span>
        <h1>Isolamento.</h1>
        <p>João está tentando isolar Maria da sua rede de apoio, no caso, da sua família, fazendo-a </p>
        <p>escolher entre os pais e ele.</p>
      </span>
    ),
    dialog: [
      //CENARIO 02
      { person: 'Maria:', line: '- Podemos jantar na casa dos meus pais neste sábado?' },
      { person: 'João:', line: '- Eu achei que tivesse te dito que eu não quero passar tempo com eles. Eles não nos entendem' },
      { person: 'Maria:', line: '- Mas eu não falo com eles há meses...' },
      { person: 'João:', line: '- Já falamos sobre isso. Você vai ter que escolher entre eles ou eu.' },
    ],
  },

  {
    //PERGUNTA 03
    id: 3,
    questionText: (
      <span>
        Qual dos sinais está evidente aqui?
      </span>
    ),
    answerOptions: [
      { questionId: 1, answerText: 'Ciúmes', isCorrect: false },
      { questionId: 2, answerText: 'Intimidação', isCorrect: false },
      { questionId: 3, answerText: 'Manipular', isCorrect: true },
    ], conclusao: (
      <span>
        <p>João está utilizando táticas clássicas de manipulação: está se mostrando chateado, culpando a Maria <br/>
        e ameaçando se auto infligir, para forçar a Maria a fazer o que ele quer fazer e para exercer controle sobre ela.<br/>
        </p>
        <p>Às vezes, os sinais acontecem ao mesmo tempo. Aumentam de intensidade ou se somam aos outros, <br/>
        resultando no que se chama de ‘agravamento’.</p>
      </span>
    ),
    dialog: [
      //CENARIO 03
      { person: 'Maria:', line: '- Eu acho que preciso dar um tempo, eu estou bastante confusa.' },
      { person: 'João:', line: '- Eu sabia que você iria fazer isso. Você nunca me amou mesmo.' },
      { person: 'Maria:', line: '- Ei, você não está sendo justo…' },
      { person: 'João:', line: '- Se você me abandonar eu vou me matar e vai tudo ser sua culpa. Como é que você consegue viver consigo mesma?' },
      { person: 'Maria:', line: '- João, não diga isso.' },
      { person: 'João:', line: '- Não, tudo bem, vá em frente. Eu não sou importante mesmo, ninguém vai sentir minha falta…' },
    ],
  },
  {
    //PERGUNTA 05
    id: 4,
    questionText: (
      <span>
        Qual dos sinais está evidente aqui?
      </span>
    ),
    answerOptions: [
      { questionId: 1, answerText: 'Manipulação', isCorrect: false },
      { questionId: 2, answerText: 'Isolamento', isCorrect: false },
      { questionId: 3, answerText: 'Controle', isCorrect: true },
    ], conclusao: (
      <span>
        Conclusão desta pergunta:
        <p>João está tentando controlar como a Maria se apresenta – e o que ela veste – mesmo sem ela 
própria concordar.</p>
      </span>
    ),
    dialog: [
      //CENARIO 04
      { person: 'João:', line: '- Me envia uma foto da roupa que você vai usar no jantar da minha empresa hoje à noite.      ' },
      { person: 'Maria:', line: '*envia a foto*      ' },
      { person: 'João:', line: '- De jeito nenhum. Você não vestir isso. Use a camisa preta e penteie seu cabelo do jeito que eu gosto.' },
      { person: 'Maria:', line: '- Mas a camisa está na lavanderia, e eu acho que essa roupa está ótima.      ' },
      { person: 'João:', line: '- O jantar é meu - você vai vestir o que eu disse ou então você não vai. Nós já falamos sobre isso.' },


    ],
  },

  {
    //PERGUNTA 04
    id: 5,
    dialog: [
      //CENARIO 04
      { person: 'João:', line: '- Eu sabia que você não ligava para mim. Melhor eu me matar mesmo.' },
      { person: 'João:', line: '- Eu vou me matar se você não responder neste instante.' },
      { person: 'João:', line: '- Me liga já, se não...' },
      { person: 'João:', line: '- Alô?! Atende! Ou eu vou até aí.' },
      { person: 'João:', line: '- ESTOU AQUI EMBAIXO, ME DEIXA ENTRAR AGORA' },
      { person: 'João:', line: '- SE VOCÊ NÃO ABRIR A PORTA EM 30 SEGUNDOS, EU JURO QUE IRÁ SE ARREPENDER.' },
    ],
    conclusao: ( 
      <div className="bg-primary mt-[-15rem] text-normal text-2xl">
        <p>ESTE ÚLTIMO EXEMPLO DEMONSTRA QUE A MANIPULAÇÃO PODE SE TRANSFORMAR EM <br />
  INTIMIDAÇÃO E PODE SE TORNAR MAIS INTENSA AO LONGO DO TEMPO.</p>
  <p className="mt-5"> OLHAR OS 9 SINAIS DE ALERTA DE ABUSO DE FORMA CRÍTICA SIGNIFICA PERCEBER QUANDO ELES <br />
  OCORREM AO MESMO TEMPO E, TAMBÉM, QUANDO ELES SE AGRAVAM.</p>
  <p className="mt-5">OLHAR OS 9 SINAIS DE ALERTA DE ABUSO DE FORMA CRÍTICA SIGNIFICA PERCEBER QUANDO ELES <br />
  OCORREM AO MESMO TEMPO E, TAMBÉM, QUANDO ELES SE AGRAVAM.<br className="pt-2"/>
  O USO DE MAIS DE UMA TÁTICA DE ABUSO PODE SIGNIFICAR UM AGRAVAMENTO. ESTEJA<br /> ATENTO(A), POIS O PERIGO PODE AUMENTAR A UM NÍVEL EM QUE DIVERSOS TIPOS DE ABUSO 
  FÍSICO, SEXUAL E PSICOLÓGICO PODEM OCORRER.</p>
  <p className="mt-5">O uso de mais de uma tática de abuso pode significar um agravamento. Esteja atenta, pois o perigo 
  pode aumentar a um nível em que diversos tipos de abuso físico, sexual e psicológico podem 
  ocorrer. </p>
</div>)
  },
 

  {
    //PERGUNTA 05
    id: 6,
    questionText: (
      <span>
        Qual dos sinais está evidente aqui?
      </span>
    ),
    answerOptions: [
      { questionId: 1, answerText: 'Chantagear', isCorrect: false },
      { questionId: 2, answerText: 'Humilhação ou menosprezar', isCorrect: true },
      { questionId: 3, answerText: 'Isolamento', isCorrect: false },
      { questionId: 4, answerText: 'Intrusão ou invadir a privacidade', isCorrect: false },
    ], conclusao: (
      <span>
        Conclusão desta pergunta:
        <p>Ao ridicularizar o corte de cabelo da Maria, João pratica a humilhação.</p>
        <p>O agravamento também pode ocorrer ao longo de várias semanas, ou até mesmo meses</p>
      </span>
    ),
    dialog: [
      //CENARIO 04
      { person: 'João:', line: '- Como está o corte de cabelo?' },
      { person: 'Maria:', line: '- Estou fazendo um corte novo ;)' },
      { person: 'João:', line: '- OK, deixe-me ver. Se estiver feio não se preocupe, você ainda pode morar comigo rs' },
      { person: 'Maria:', line: '*envia foto*' },
      { person: 'João:', line: '- Nossa, você ficou ridícula. 🤣' },
    ],
  },
];