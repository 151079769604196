import React from 'react'
import { useNavigate } from 'react-router-dom'
import nextButton from '../assets/nextButton.png';

function Conclusion6() {
    const navigate = useNavigate();
    const handleNextClick = () => {
        navigate('/sinais')
    }
  return (
    <div className='mx-12 flex flex-col items-center justify-center h-screen'>
    <p className='uppercase text-xl'>Pergunta 06</p>
    <p className='text-xl mb-8 mt-2'>Conclusão desta pergunta:</p>
    <p className='text-center text-3xl'>
    <span>
        Conclusão desta pergunta:
        <p>Ao ridicularizar o corte de cabelo da Maria, João pratica a humilhação.</p>
        <p>O agravamento pode, também, ocorrer ao longo de várias semanas ou até mesmo meses</p>
      </span>
    </p>

      <div className='absolute flex justify-end mt-8 mb-1 inset-x-0 bottom-0 mx-1'>
        <button className='p-2 mb-1' onClick={handleNextClick}>
          <img
            src={nextButton}
            alt='Próximo'
            className='w-[4rem] object-cover'
          />
        </button>
      </div>
      </div>
  )
}

export default Conclusion6