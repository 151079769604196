import React, { useState } from 'react'
import logo from '../assets/logo.png';
import nextButton from '../assets/nextButton.png';
import FullScreenButton from '../components/FullScreenButton';
import { useNavigate } from 'react-router-dom';
import NavigateButtons from '../components/NavigateButtons';

import Header from '../components/Header';


function StartScreen() {

  const navigate = useNavigate();

  const goToNextPage = () => {
    navigate('/amor');
  };

  return (
    <div className="relative h-screen bg-primary ">
      <Header />
      <div className='flex justify-end mx-2'>
        <FullScreenButton />
      </div>

      <div className="flex items-center justify-center mt-32 place-items-center my-auto ">
        <div className="grid grid-cols-2 gap-5 mx-12 pt-8 mr-6">
          <div >
            <h1 className="uppercase text-5xl font-semibold mt-8">Abuso não é amor</h1>
            <p className="text-2xl mb-5 mt-12 pt-2">
              Seja físico, sexual, psicológico ou financeiro,
              ABUSO É ABUSO e pode acontecer com qualquer um.
            </p>
            <p className="text-2xl">
              Muitas vezes, os sinais de alerta de abuso são mascarados como amor pelo parceiro.
            </p>
          </div>
          <div>
            <p className="text-2xl mt-32 pt-5">
              Quando se trata de relacionamentos abusivos, muitas pessoas desconhecem os sinais ou se
              recusam a reconhecê-los. Ao participar deste treinamento, você entenderá melhor os sinais
              de alerta de abuso que muitas vezes se transformam em tipos mais graves de abuso
              físico, psicológico e sexual.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <NavigateButtons previousPage="/" nextPage="/amor" nextLabel="Próximo" allowedTurnBack={false} allowedToAdvance={true} />
      </div>

    </div>
  );
}

export default StartScreen;
