import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBHS_zUJXrL8blgToO989FB9PFKiH7I36U",
  authDomain: "quizabuseisnotlove.firebaseapp.com",
  projectId: "quizabuseisnotlove",
  storageBucket: "quizabuseisnotlove.appspot.com",
  messagingSenderId: "932589995638",
  appId: "1:932589995638:web:ec136e17e86a348f890157"
};
  
export const FIREBASE_APP = initializeApp(firebaseConfig)
export const FIRESTORE_DB = getFirestore(FIREBASE_APP);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);