import React from 'react';
import { Link } from 'react-router-dom';


const NotFound = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-500 mb-4">404</h1>
                <p className="text-2xl text-gray-800 mb-8">Página não encontrada</p>
                <p className="text-lg text-gray-600">A página que você está procurando pode ter sido removida, renomeada ou está temporariamente indisponível.</p>
                <Link to="/" className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">
                    Voltar para a Página Inicial
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
