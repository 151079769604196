import React from 'react'
import nextButton from '../assets/nextButton.png';
import nextButtonR from '../assets/nextButtonR.png';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'
import Header from '../components/Header'

import NavigateButtons from '../components/NavigateButtons';


function StartScreenTwo() {

  const navigate = useNavigate();

  const goToNextPage = () => {
    navigate('/autoconhecimento');
  }

  const goToBack = () => {
    navigate('/')
  }
  return (
    <>
      <div>
        <Header />
      </div>
      <section className='items-center place-items-center place-content-center pt-2'>

        <div className='flex justify-between mb-12 items-center mx-5'>

          <h1 className='text-4xl '>AO PARTICIPAR DESTE <br /> TREINAMENTO, VOCÊ SABERÁ O QUE <br /> <span className='text-[#ED2024] flex-wrap: wrap' > A.M.O.R. </span> SIGNIFICA.</h1>

        </div>

        <div className='place-content-evenly flex items-center h-full mx-8'>

          <div className='flex gap-32 sm:gap-20'>
            <div className='' >
              <p className='mb-2 text-[#ED2024] text-6xl'>A</p>
              <p className='mb-6 text-3xl'>APRENDA</p>
              <p className='text-xl'>Aprenda os sinais de <br />comportamentos  <br />abusivos </p>
            </div>

            <div className='flex gap-32 sm:gap-20'>
              <div className='' >
                <p className='mb-2 text-[#ED2024] text-6xl'>M</p>
                <p className='mb-6 text-3xl'>MANIFESTE APOIO</p>
                <p className='text-xl'>Mostre apoio a<br />quem sofre abuso </p>
              </div>
            </div>

            <div className='flex gap-32 sm:gap-20'>
              <div className='' >
                <p className='mb-2 text-[#ED2024] text-6xl'>O</p>
                <p className='mb-6 text-3xl'>OBSERVE</p>
                <p className='text-xl'>Observe atentamente <br />para tomar medidas <br /> contra o abuso</p>
              </div>
            </div>

            <div className='flex gap-32 sm:gap-20'>
              <div className='' >
                <p className='mb-2 text-[#ED2024] text-6xl'>R</p>
                <p className='mb-6 text-3xl'>REALINHE AS <br /> EXPECTATIVAS </p>
                <p className='text-xl'>Espere o melhor de <br />seus relacionamentos</p>
              </div>
            </div>
          </div>

        </div>
        <div className="flex justify-between">
          <NavigateButtons previousPage="/" nextPage="/autoconhecimento" previousLabel="Anterior" nextLabel="Próximo" allowedTurnBack={true} allowedToAdvance={true} />
        </div>

      </section>
    </>
  )
}

export default StartScreenTwo