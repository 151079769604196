import React, { useState, useEffect } from 'react';
import { FIRESTORE_DB } from '../firebase';
import { collection, doc, getDoc, query, where, onSnapshot } from 'firebase/firestore';
import { PieChart } from '@mui/x-charts';
import '../App.css';
//import DataList from '../components/DataList'; //FIXME: Inserir DataList (pegar do quiz feito para SWISS)
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Fab from '@mui/material/Fab';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
//import { exportToExcel } from '../components/excelUtils'; //FIXME inserir componente ExcelUtils (pegar do quiz feito para SWISS)


function Dashboard() {
  const [clickCounts, setClickCounts] = useState([]);
  const [allData, setAllData] = useState([]);

  const navigate = useNavigate();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      dark: "#120a1c"
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const startCountDoc = await getDoc(doc(FIRESTORE_DB, 'counters', 'startButtonCount'));
        const startCount = startCountDoc.data()?.count || 0;

        const quizzesRef = collection(FIRESTORE_DB, 'quizzes');
        const finishedUsersQuery = query(quizzesRef, where('endTime', '!=', null));


        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(finishedUsersQuery, (snapshot) => {
          const finishedUsersCount = snapshot.docs.length;

          // Atualizar clickCounts
          setClickCounts([startCount, finishedUsersCount]);

          // Armazenar dados em allData
          const newDataFromSnapshot = snapshot.docs.map((doc) => doc.data());
          setAllData((prevData) => [...prevData, ...newDataFromSnapshot]);
        });

        return () => {
          // Unsubscribe when the component unmounts
          unsubscribe();
        };
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
    };

    fetchData();
  }, []);

  let start = clickCounts[0];
  let finish = clickCounts[1] || 0; // Use 0 if undefined
  let unFinished = start - finish;

  const closePdfHandler = () => {
    navigate('/pdf');
  };

  // const handleOnExport = () => {
  //   console.log(allData);
  //   exportToExcel(allData); // Chamando a função de exportação para o Excel
  // };//FIXME: ARRUMAR, AINDA NÃO POSSUI EXCEL

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className='bg-[#000] h-screen relatorio-container px-12'>
        <h2 className='text-3xl text-center text-[#fff] pt-12'>Relatório</h2>
        {/*<img src={logo} alt='logo' className='mx-auto h-12' /> */}//FIXME: Sem logo ainda

        <PieChart className='text-white mb-12'
          series={[
            {
              data: [
                { id: 0, value: start, label: 'Iniciaram' },
                { id: 1, value: finish, label: 'Terminaram' },
                { id: 2, value: unFinished, label: 'Iniciaram, não terminaram' },
              ],
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          width={1000}
          height={500}
        />

        {/* <DataList /> */} //FIXME: descomentar quando inserir DataList

        <Fab
          color="primary"
          aria-label="Visualizar PDF"
          style={{ position: 'fixed', top: 16, right: 16, opacity: 0.8, color: '#fff' }}
          onClick={closePdfHandler}
        >
          <PictureAsPdfIcon />
        </Fab>

        {/* <Fab
          color="primary"
          aria-label="Exportar Excel"
          style={{ position: 'fixed', top: 90, right: 16, opacity: 0.8, color: '#fff' }}
          onClick={handleOnExport} // Chame a função excelHandler ao clicar no botão
        >
          <SaveAltIcon />
        </Fab> */} //FIXME: ARRUMAR, AINDA NÃO POSSUI EXCEL
        <footer>
          <img className='h-12 mx-au' src="https://static.wixstatic.com/media/b1ca23_678ea2c291284b3186fe1f4bb9a742ce~mv2.png/v1/crop/x_0,y_217,w_1920,h_661/fill/w_395,h_136,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b1ca23_678ea2c291284b3186fe1f4bb9a742ce~mv2.png" alt='logo Phygital Lab' />
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default Dashboard;
