import React from 'react';
import { data } from '../data/extraPageData';
import NavigateButtons from '../components/NavigateButtons';
import Header from '../components/Header';

function ExtraScreen() {
  return (
    <div className='bg-primary'>
      <Header/>
      <div className="text-center">
        <h1 className='text-3xl ml-28 '>
          UM RELACIONAMENTO
          <br />
          ABUSIVO PODE APRESENTAR
          <br />
          <span className='text-[#F69CA5]'>9 PRINCIPAIS SINAIS DE</span>
          <br />
          <span className='text-[#E02727]'>ALERTA</span>
        </h1>
        <div className="grid grid-cols-3 mx-auto mt-5 place-content-center place-items-center">
          {data.map((item, index) => (
            <div key={index} className="p-4 rounded-md items-center">
              <h2 className="text-xl">{item.title}</h2>
              <div>{item.content}</div>
            </div>
          ))}
        </div>
        <NavigateButtons previousPage="/sinais" nextPage="/treinamento" previousLabel="Anterior" nextLabel="Próximo" allowedTurnBack={true} allowedToAdvance={true} />

      </div>
    </div>
  );
}

export default ExtraScreen;
