import React from 'react'
import nextButton from '../assets/nextButton.png';
import nextButtonR from '../assets/nextButtonR.png';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'

import Header from '../components/Header';
import NavigateButtons from '../components/NavigateButtons';




function StartScreenFour() {

    const navigate = useNavigate();

    const goToNextPage = () => {
        navigate('/treinamento');
    }

    const goToBack = () => {
        navigate('/')
    }

    return (
        <section className='items-center place-items-center place-content-center'>
            <Header/>
            <div className="flex justify-end">
            </div>

            <div className='px-12 py-1' >
                <h1 className='text-3xl mb-2 pt-2' >O que é um relacionamento abusivo? </h1>
                <div className='grid gap-4' >
                    <span>
                        <p className='font-semibold xl' >RELACIONAMENTO SAUDÁVEL</p> 
                        <p className='text-lg' >
                            Todo relacionamento é diferente, mas em um relacionamento saudável, os limites são respeitados e os parceiros  <br />
                            têm uma comunicação aberta e honesta.
                        </p>
                    </span>

                    <span>
                        <p className='font-semibold xl' >
                            RELACIONAMENTO ABUSIVO
                        </p>
                        <p className='text-lg' >
                            Em um relacionamento abusivo, os limites são ignorados ou extrapolados, e um(a) dos parceiros(as) procura ter  <br />
                            poder sobre o outro(a) para controlá-lo(a).
                        </p>
                    </span>

                    <span>
                        <p className='font-semibold xl' >
                            VIOLÊNCIA POR PARCEIRO ÍNTIMO (VPI)
                        </p>
                        <p className='text-lg' >
                            É o termo que usamos para descrever quando um parceiro exerce PODER OU CONTROLE sobre alguém com quem <br />
                            está (ou esteve) em um relacionamento. Ela pode assumir várias formas,podendo ser: psicológica, sexual ou física.  <br />
                            Pode acontecer com qualquer pessoa em um casal, independentemente da sua idade, gênero, ou se residem na  <br />
                            mesma residência.
                        </p>
                    </span>
                    <p className='text-lg' >Esses comportamentos abusivos são praticados com a intenção de provocar medo e de controlar o(a) parceiro(a)”.</p>
                    <p className='text-lg' >Um relacionamento abusivo pode apresentar 9 principais sinais de alerta:</p>
                    <p className='text-lg' >Ignorar; Chantagear; Humilhar; Manipular; Ciúmes; Controlar; Intrusão; Isolamento; Intimidação</p>
                </div>

            </div>
            <NavigateButtons previousPage="/autoconhecimento" nextPage="/extra" previousLabel="Anterior" nextLabel="Próximo" allowedTurnBack={true} allowedToAdvance={true} />
        </section>
    )
}

export default StartScreenFour